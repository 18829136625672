import {
  Dispatch,
  Middleware,
  MiddlewareAPI,
  UnknownAction,
} from "@reduxjs/toolkit";
import { RootState } from "../store";
import { checkIfErrorIsResolved as checkIfVoitureErrorIsResolved } from "../feature/assuranceVoiture/slice";
import { checkIfErrorIsResolved as checkIfSanteErrorIsResolved } from "../feature/assuranceSante/slice";

const monitorServicesErrors: Middleware =
  (store: MiddlewareAPI<Dispatch<UnknownAction>, RootState>) =>
  (next) =>
  (action: any) => {
    next(action);

    if (action.type === "assuranceVoiture/updateData") {
      const errors = store.getState().services.assuranceVoiture.stepWithError;

      if (errors.length > 0) {
        errors.forEach((error) => {
          store.dispatch(checkIfVoitureErrorIsResolved(error));
        });
      }
    }

    if (action.type === "assuranceSante/updateData") {
      const errors = store.getState().services.assuranceSante.stepWithError;

      if (errors.length > 0) {
        errors.forEach((error) => {
          store.dispatch(checkIfSanteErrorIsResolved(error));
        });
      }
    }
  };

export default monitorServicesErrors;
